import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

import { updatePreviousRoute } from '../../../store/ducks/route';
import { findCustomer } from '../../../store/ducks/customer';
import { populateUserForm, createUser, updateUser } from '../../../store/ducks/user';
import useStyles from './styles';
import UserForm from './UserForm';

const UserControl = ({ 
  match: { 
    url, 
    params: { 
      customerId, 
      userId 
    } 
  }, 
  updatePreviousRoute, 
  form, 
  roles, 
  findCustomer, 
  selectedCustomer, 
  populateUserForm, 
  createUser, 
  updateUser
}) => {

  const classes = useStyles();

  useEffect(() => {

    return () => updatePreviousRoute(url)

  }, [updatePreviousRoute, url]);

  useEffect(() => {

    findCustomer(customerId);

    if (userId)
      populateUserForm(userId);
    else
      populateUserForm();

  }, [findCustomer, customerId, populateUserForm, userId]);

  const configTextFields = useCallback(
    () => {
      const textFields =   [
        { name: 'name', label: 'Nome' },
        { name: 'email', label: 'Email' },
        { name: 'address', label: 'Endereço' },
        { name: 'password', label: 'Senha', isPassword: true }
      ]

      if (userId)
        textFields.pop();
    
      return textFields;
    }, 
    [userId]
  );

  return (
    <Box className={classes.userControlRoot}>

      {!form.user &&
        <Grid
          container
          classes={{ root: classes.userControlContent}}
        >
          <Grid item xs={12}>
            <LinearProgress color="secondary" />
          </Grid>
        </Grid>
      }

      {form.user &&
        <UserForm 
          textFields={configTextFields()}
          roles={roles}
          parentSubmitHandle={
            values => {
              if (userId)
                updateUser(userId, values);
              else
                createUser(values);
            }
          }
        />
      }

    </Box>
  );

};

const mapStateToProps = state => ({
  roles: state.roles.data, 
  selectedCustomer: state.customers.selectedCustomer,
  form: state.form
});

const mapDispatchToProps = {
  updatePreviousRoute, 
  findCustomer, 
  createUser, 
  populateUserForm, 
  updateUser 
};

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(UserControl);
