import { takeLatest, take, all, put, call, select, delay } from 'redux-saga/effects';
import { initialize, startSubmit, stopSubmit } from 'redux-form';

import { Types as UserTypes } from '../ducks/user';
import { Types as TableTypes } from '../ducks/table';
import { Types as RoleTypes } from '../ducks/role';
import { Types as CustomComponentsTypes } from '../ducks/customComponents';
import { 
  fetchUsers, 
  createUser, 
  findUser, 
  updateUser, 
  deleteUser, 
  importUser, 
  logImportUser 
} from '../../services/user';

import { history } from '../../routes';
import apiValidate from '../../validators/apiValidate';

function* getRoleIds(formRoles) {
  const stateRoles = yield select(state => state.roles.data);

  const selectedRoles = stateRoles.filter((role, index) => formRoles[index]);

  return selectedRoles.map(selectedRole => selectedRole.id);
};

function setupPhoneFields(phoneFields) {
  if(!phoneFields)
    return [];

  const configuredFields =
    phoneFields
      .filter(field => field)
      .map(field => ({
        name: field,
        type: 1
      }))

  return configuredFields
}

function* asyncFetchPaginatedUsers({ payload }) {

  yield put({ type: UserTypes.SET_USERS_LOADING, payload: { loading: true } });

  const customerId = yield select(state => state.auth.selectedCustomerId);

  let { page = 1, perPage = 10 } = payload;

  const searchTerm = yield select(state => state.customComponents.searchbarTerm);
  
  let params = { customerId, perPage, page, searchTerm };

  let { lastPage, data, total, error } = yield call(fetchUsers, params);

  if (error) {

    yield put({ type: UserTypes.FAIL_FETCH_USERS  });

  } else {
    if (lastPage < page) {
      page = lastPage;

      params = { ...params, page };
  
      ({ data, total } = yield call(fetchUsers, params));
    }

    yield all([
      put({ type: TableTypes.UPDATE_TABLE_PAGINATION, payload: { totalRows: total, currentPage: page, perPage, lastPage } }),
      put({ type: UserTypes.SUCCESS_FETCH_USERS, payload: { users: data } }),
      put({ type: UserTypes.SET_USERS_LOADING, payload: { loading: false } })
    ]);
  }

};

function* asyncPopulateUserForm({ payload }) {
  let initialValues = {};

  yield put({ type: RoleTypes.ASYNC_FETCH_ROLES });

  yield take(RoleTypes.SUCCESS_FETCH_ROLES);

  const stateRoles = yield select(state => state.roles.data);

  const { userId } = payload;

  if (userId) {
    const { data: { name, email, username, address, roles, phones } } = yield call(findUser, userId);

    const formPhones = phones.map(phone => phone.name);

    const formRoles = stateRoles.map(stateRole => {
      return roles.find(userRole => userRole.id === stateRole.id) ? true : false;
    });

    initialValues = { name, email, username, address, phones: formPhones, roles: formRoles }
  } else {
    const formRoles = stateRoles.map(() => false);

    initialValues = { roles: formRoles };
  }

  yield put(initialize('user', initialValues));
};

function* asyncCreateUser({ payload: { formData } }) {

  yield put({ type: UserTypes.SET_USERS_LOADING, payload: { loading: true } });

  const customerId = yield select(state => state.auth.selectedCustomerId);

  const roleIds = yield call(getRoleIds, formData.roles);

  const phones = yield call(setupPhoneFields, formData.phones)

  const { error } = yield call(createUser, { ...formData, username: formData.email, customerId, roles: roleIds, phones });

  if (error) {
    const { errors, message, statusCode } = error;

    let errorsObject;

    if (statusCode === 422)
      errorsObject = apiValidate(errors);

    yield all([
      put({ type: UserTypes.SET_USERS_LOADING, payload: { loading: false } }),
      put(stopSubmit('user', errorsObject)),
      put({ type: CustomComponentsTypes.HANDLE_SNACKBAR, payload: { message } })
    ]);
  } else {
    yield all([
      put({ type: CustomComponentsTypes.HANDLE_SNACKBAR, payload: { message: 'Usuário cadastrado com sucesso' } }),
      call(history.push, `/customers/${customerId}/users`),
      put({ type: UserTypes.SET_USERS_LOADING, payload: { loading: false } }),
    ]);
  }

};

function* asyncUpdateUser({ payload: { userId, formData } }) {

  yield put({ type: UserTypes.SET_USERS_LOADING, payload: { loading: true } });

  const customerId = yield select(state => state.auth.selectedCustomerId);

  const roleIds = yield call(getRoleIds, formData.roles);

  const phones = yield call(setupPhoneFields, formData.phones);

  const { error } = yield call(updateUser, userId, { ...formData, username: formData.email, customerId, roles: roleIds, phones });

  if (error) {
    const { errors, message, statusCode } = error;

    let errorsObject;

    if (statusCode === 422)
      errorsObject = apiValidate(errors);

    yield all([
      put({ type: UserTypes.SET_USERS_LOADING, payload: { loading: false } }),
      put(stopSubmit('user', errorsObject)),
      put({ type: CustomComponentsTypes.HANDLE_SNACKBAR, payload: { message } })
    ]);
  } else {
    yield all([
      put({ type: CustomComponentsTypes.HANDLE_SNACKBAR, payload: { message: 'Usuário atualizado com sucesso' } }),
      call(history.push, `/customers/${customerId}/users`),
      put({ type: UserTypes.SET_USERS_LOADING, payload: { loading: false } })
    ]);
  }

};

function* asyncDeleteUser({ payload: { userId } }) {

  const { page, perPage } = yield select(state => ({ page: state.table.currentPage, perPage: state.table.perPage }));

  const { error } = yield call(deleteUser, userId);

  if (error) {
    yield all([
      put({ type: CustomComponentsTypes.HANDLE_SNACKBAR, payload: { message: 'Não foi possível excluir o usuário' } }),
      put({ type: CustomComponentsTypes.HANDLE_DIALOG, payload: { open: false } })
    ]);
  } else {
    yield all([
      put({ type: UserTypes.ASYNC_FETCH_PAGINATED_USERS, payload: { page, perPage } }),
      put({ type: CustomComponentsTypes.HANDLE_SNACKBAR, payload: { message: 'Usuário excluído com sucesso' } }),
      put({ type: CustomComponentsTypes.HANDLE_DIALOG, payload: { open: false } })
    ]);
  }
}

function* asyncImportUser({ payload }) {

  const selectedCustomerId = yield select(state => state.auth.selectedCustomerId);
  
  yield put(startSubmit('FORM_IMPORT_USER'));

  try {
    const { data } = yield call(importUser, {
      ...payload,
      //change_passport_first_access: payload.change_passport_first_access ? 1 : 0,
      roles: payload.roles.filter(item => item.checked).map(item => item.role.id)
    });

    yield call(history.push, `/customers/${selectedCustomerId}/users/import/${data.log_id}`)

    //const { data : logs } = yield call(logImportUser, data.log_id);

  } catch (err) {
    
  }

  yield put(stopSubmit('FORM_IMPORT_USER'));
}

function* asyncLoadLogImportUser({ payload }) {

  yield put({ type: UserTypes.LOG_IMPORT_USERS_LOADED, payload: { loading: true, data: null }});

  try {
    
    while(true) {
      const { data } = yield call(logImportUser, payload.id);    
      yield put({ type: UserTypes.LOG_IMPORT_USERS_LOADED, payload: { loading: false, data: data }});

      if(data.errors.length + data.success.length === data.total) {
        break;
      }

      yield delay(5000);
    }

  } catch (err) {

    const selectedCustomerId = yield select(state => state.auth.selectedCustomerId);
    yield all([
      put({ type: UserTypes.LOG_IMPORT_USERS_LOADED, payload: { loading: false, data: null }}),
      put({ type: CustomComponentsTypes.HANDLE_SNACKBAR, payload: { message: 'Log não encontrado' }}),
      call(history.push, `/customers/${selectedCustomerId}/users`)
    ]);
  }
}

export default function* userSaga() {
  yield all([
    takeLatest(UserTypes.ASYNC_FETCH_PAGINATED_USERS, asyncFetchPaginatedUsers),
    takeLatest(UserTypes.ASYNC_POPULATE_USER_FORM, asyncPopulateUserForm), 
    takeLatest(UserTypes.ASYNC_CREATE_USER, asyncCreateUser),
    takeLatest(UserTypes.ASYNC_UPDATE_USER, asyncUpdateUser),
    takeLatest(UserTypes.ASYNC_DELETE_USER, asyncDeleteUser),
    takeLatest(UserTypes.ASYNC_IMPORT_USER, asyncImportUser),
    takeLatest(UserTypes.ASYNC_LOAD_LOG_IMPORT_USERS, asyncLoadLogImportUser)
  ])
};
