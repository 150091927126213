import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import { updatePreviousRoute } from '../../store/ducks/route';
import { handleSnackbar, handleDialog } from '../../store/ducks/customComponents';
import { fetchPaginatedUsers, deleteUser } from '../../store/ducks/user';
import useStyles from './styles';

import Acl from '../../components/Acl';
import SearchBar from '../../components/SearchBar';
import CustomTable from '../../components/CustomTable';
import CustomSnackbar from '../../components/CustomSnackbar';
import CustomDialog from '../../components/CustomDialog';
import SelectCustomer from '../../components/SelectCustomer';

const User = ({ 
  match: { url }, 
  selectedCustomerId, 
  updatePreviousRoute, 
  loading, 
  fetchPaginatedUsers, 
  users, 
  searchbarTerm, 
  deleteUser, 
  handleSnackbar, 
  handleDialog,
}) => {

  const classes = useStyles();

  const [userIdForDelete, selectUserIdForDelete] = useState(null);

  useEffect(() => {

    return () => updatePreviousRoute(url)

  }, [updatePreviousRoute, url]);


  const formatDataToTable = useCallback(
    () => {
      // Column Content
      const usersData = users.map(user => {

        const { id, name, email, roles, phones } = user;

        const deleteIconButton = 
          <Acl permission="can-delete-users">
            <Tooltip title="Excluir" placement="left">
              <IconButton 
                aria-label="deletar" 
                style={{ marginRight: 20 }}
                onClick={() => {
                  handleDialog(true);
                  selectUserIdForDelete(id);
                }}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </Tooltip>
          </Acl>;

        const createIconButton = 
          <Acl permission="can-edit-users">
            <Tooltip title="Editar" placement="right">
              <IconButton 
                aria-label="editar" 
                component={Link}
                to={`${url}/${id}/update`}
              >
                <CreateIcon color="secondary" />
              </IconButton>
            </Tooltip>
          </Acl>;

        const icons = 
          <Box>
            {deleteIconButton}{createIconButton}
          </Box>;

        const phone = phones.length > 0 ? phones[0].name : 'Não há telefones cadastrados';
        
        const roleNames = roles.length > 0 ? 
          roles
            .map(role => role.name)
            .join(', ') : 
          'Não há cargos associados a este usuário'

        return { id, name, email, roles: roleNames, phone, icons: icons ? icons : null };

      });

      // Column Header
      const headers = [
        { label: 'Nome' }, 
        { label: 'Email' }, 
        { label: 'Cargos' }, 
        { label: 'Tel. de Contato' },
        { label: (<Box style={{ padding: 24 }}></Box>)  }
      ];

      return { headers, usersData };
    }, 
    [users, handleDialog, selectUserIdForDelete, url]
  );

  useEffect(
    () => {
      fetchPaginatedUsers();
    }, 
    [fetchPaginatedUsers, selectedCustomerId, searchbarTerm]
  );

  useEffect(
    () => 
      () => handleSnackbar(), 
    [handleSnackbar]
  );

  const dataFormatted = formatDataToTable();

  return (
    <Grid container classes={{ root: classes.userRoot }} >
      <Grid item xs={12}>
        <SelectCustomer url={url}/>
      </Grid>

      <Grid 
        item 
        xs={12} 
        md={6} 
        container 
        alignItems="center" 
        style={{ padding: 20 }} 
      >
        <SearchBar
          name="search-users"
          label="Pesquisar usuários"
        />
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        container
        alignItems="center"
        justify="flex-end"
        classes={{ root: classes.userButtonContainer }}
      >
        <Acl permission="can-import-users">
          <Button
            variant="outlined"
            color="secondary"
            component={Link}
            classes={{ root: classes.importUsersButton }}
            to={{ pathname: `${url}/import` }}>

            Importar Usuários
          </Button>
        </Acl>

        <Acl permission="can-create-users">
          <Button 
            variant="contained" 
            color="secondary" 
            component={Link}
            to={`${url}/new`}
          >
            Novo Usuário
          </Button>
        </Acl>
      </Grid>

      <Grid 
        item 
        xs={12} 
        classes={{ root: classes.usersTableContainer }}
      >
        <CustomTable 
          tableLoading={loading}
          tablePadding="checkbox"
          tableHeaders={dataFormatted.headers} 
          tableData={dataFormatted.usersData} 
          parentHandlePagination={(page, perPage) => fetchPaginatedUsers(page, perPage)} 
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <CustomDialog
          parentHandleClose={action => {
            if (action === 'Cancelar') {
              selectUserIdForDelete(null);
              handleDialog(false);
            } else {
              deleteUser(userIdForDelete);
              handleDialog(false);
            }
          }}
        />

        <CustomSnackbar variant="success" />
      </Grid>
    </Grid>
  );

};

const mapStateToProps = state => ({
  selectedCustomerId: state.auth.selectedCustomerId, 
  loading: state.users.loading, 
  users: state.users.data,
  searchbarTerm: state.customComponents.searchbarTerm
});

const mapDispatchToProps = {
  updatePreviousRoute, 
  fetchPaginatedUsers, 
  deleteUser, 
  handleSnackbar, 
  handleDialog
};

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(User);
